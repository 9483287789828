import { useEffect, useRef } from 'react';
import {
	cityName,
	textReplacement,
	getLocPhysicalAndCity,
	adRefCityText
} from '../helpers/adRefTracking';

export const useTextReplacement = (
	textToReplaceWith,
	replacementContainerClassName = 'personalizedText'
) => {
	const { city, locphysical } = getLocPhysicalAndCity() || {};
	const personalizedTextRef = useRef(null);
	useEffect(() => {
		if (textToReplaceWith || city || locphysical) {
			textReplacement(
				personalizedTextRef,
				replacementContainerClassName,
				textToReplaceWith ||
					adRefCityText(city, { locphysical, city }) ||
					cityName(city)
			);
		}
	});
	return personalizedTextRef;
};
