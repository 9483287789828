export const getSchemaSameAs = social => {
	const list = [];
	Object.keys(social).forEach((key, index) => {
		const item = social[key];
		if (item) {
			list.push(item);
		}
	});
	return list;
};
