import React from 'react';
import styled from 'styled-components';
import bbb from '../images/product-badges/bbb-xs.png';
import lp from '../images/product-badges/lp.png';
import epa from '../images/product-badges/epa-lead-safe-white.png';
import nari from '../images/product-badges/nari.png';
import jameshardie from '../images/product-badges/jameshardie.png';

export default class FeaturedProductBadges extends React.Component {
	render() {
		let { props } = this;

		const Reputation = styled.div``;
		const CardHeader = styled.h3`
			margin: 0;
		`;
		const CardHeaderContainer = styled.div`
			background: #f7f7f7;
			padding: 1em;
			margin: 0;
			border-radius: 0.25em 0.25em 0 0;
		`;
		const ReviewsGrid = styled.div`
			display: flex;
			flex-wrap: wrap;
		`;
		const ReviewItem = styled.div`
			padding: 0.25em 1em;
			flex: ${this.props && this.props.largeScreen
				? '0 0 25%'
				: '0 0 50%'};
			padding: 2% 4%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			img {
				margin-bottom: 5px;
				max-width: 100%;
				margin-left: 0;
				margin-right: 0;
				margin-top: 0;
				padding: 0;
			}
		`;
		const ReviewItemDetails = styled.div`
			h4,
			p {
				margin: 0;
				text-align: center;
				font-weight: normal;
			}
			h4 {
				margin-top: 0.25em;
				margin-top: 0.25em;
				font-size: 0.9em;
				color: #666;
				line-height: 1.25;
			}
			p {
				font-size: 0.65em;
				line-height: 1.1;
				padding-top: 0.25em;
			}
		`;

		return (
			<Reputation
				className={
					this.props && this.props.largeScreen
						? 'reputation-bar-full-width'
						: null
				}
			>
				<ReviewsGrid className="reviewsecbdg">
					<ReviewItem className="reviewsecbdgit">
						<img src={bbb} alt="BBB A+" />
						<ReviewItemDetails>
							<h4>BBB Accredited</h4>
						</ReviewItemDetails>
					</ReviewItem>

					<ReviewItem className="reviewsecbdgit">
						<img src={lp} alt="LP" />
						<ReviewItemDetails>
							<h4>LP Preferred Contractor</h4>
						</ReviewItemDetails>
					</ReviewItem>
					<ReviewItem className="reviewsecbdgit">
						<img
							src={epa}
							alt="EPA Lead Safe Certified Firm"
							className="epaimg"
						/>
						<ReviewItemDetails>
							<h4>EPA Lead Certified Renovator</h4>
						</ReviewItemDetails>
					</ReviewItem>

					<ReviewItem className="reviewsecbdgit">
						<img
							src={jameshardie}
							alt="James Hardie Elite Preferred Contractor Alliance"
						/>
						<ReviewItemDetails>
							<h4>James Hardie Elite Preferred Contractor</h4>
						</ReviewItemDetails>
					</ReviewItem>
				</ReviewsGrid>
			</Reputation>
		);
	}
}
